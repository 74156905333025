<template>
<div class="bg-white w-full rounded shadow lg:shadow-none sterfiets-info">
  <div class="mt-4 px-4 fixed w-full h-8 top-0 left-0 z-30 lg:hidden">
    <a @click="closeRouteInfo" class="block flex w-full h-full bg-blue-500 rounded-t">
      <svg-inline class="w-4 m-auto" name="svg-close" />
    </a>
  </div>
  <div class="info w-full relative p-4 pt-12 lg:pt-8 border-b">
    <h2 class="text-3xl">Algemeen</h2>    
    <div class="w-full flex flex-wrap mt-4">
      <div>
        <h3 class="text-xl mb-1">Sterfietsroutes</h3>
        <div class="flex flex-wrap items-end">
          <h4 class="text-5xl font-bold">{{ data.aantal }}</h4>
        </div>
      </div>
    </div>
  </div>
  <div class="p-4 border-b">
    <h3 class="text-xl mb-1">Totaal aantal kilometers</h3>
    <div class="flex flex-wrap items-end">
      <h4 class="text-5xl font-bold">{{ data.kilometers }}</h4>
      <span class="text-base font-bold ml-2 mb-1">km</span>
    </div>
  </div>
  <div class="p-4 border-b">
    <h3 class="text-xl mb-1">Aantal fietsers</h3>
    <div class="flex flex-wrap items-end">
      <h4 class="text-5xl font-bold">{{ data.fietsers }}</h4>
      <span class="text-base font-bold ml-2 mb-1">per dag</span>
    </div>
  </div>  
  <div class="p-4">
    <h3 class="text-xl mb-1">Informatie</h3>
    <p class="text-base font-light">
      {{ data.informatie }}
    </p>
  </div>
</div>
</template>

<script>

export default {
	props: {
		data: {
			type: [Object],
			custom: false
		}
	},
  methods: {
    closeRouteInfo() {
      this.$emit('closeRouteInfo')
    }
  }
}
</script>

<style lang="scss" scoped>
  .sterfiets-info:after {    
    content: '';
    z-index: -1;
    @apply fixed top-0 left-0 bg-black h-full w-full opacity-50;
    @screen lg { @apply hidden; }
  }
</style>