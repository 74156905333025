<template>
  <div class="fixed w-full h-full bg-black z-50 flex flex-wrap justify-center lg:content-center overflow-y-auto" :class="{ hidden: !openMenu }">
    <div class="py-12 px-4">
      <h2 class="text-white text-3xl lg:text-5xl mb-4 lg:mb-8 font-bold leading-none">Selecteer een sterfietsroute</h2>
      <ul class="block text-left">
        <li v-for="(route, index) in routes" :key="index" class="text-white text-lg lg:text-2xl mb-4 relative">
          <a @click="selectRoute(index, $event)" href="#" :title="route[0].route.properties.LEGENDA_1" class="flex items-center font-normal underline hover:no-underline leading-tight">
            <span class="inline-block w-2 lg:w-4 h-0.5 lg:h-1 mr-2 lg:mr-3" :style="{ background: routesInfo[index - 1].color }" />{{ route[0].route.properties.LEGENDA_1 }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'Menu',
	props: {
		map: {
			type: [Object],
			custom: false
		},
		data: {
			type: [Object],
			custom: false
		}
	},
  data: function () {
    return {
      routesInfo: this.data.sterfietsroutes
    }
  },
  computed: {
    ...mapState(['routes', 'openMenu', 'stops'])
  },  
  mounted() {

  },
  methods: {
    selectRoute (index, event) {
      event.preventDefault()
      this.map.closePopup()
      for (let key in this.routes) {
        this.routes[key].forEach(function (item) {
          item.leaflet.setStyle({ "weight": 5 })
        });
      }
      this.routes[index].forEach(function (item) {
        item.leaflet.setStyle({ "weight": 8 })
        item.leaflet.bringToFront()
      });
      this.stops.getLayers().forEach((item) => {
        item.bringToFront()
      });
      this.$store.commit('openMenu', !this.openMenu)
      this.$store.commit('setActive', index - 1)
      this.$store.commit('setName', this.routes[index][0].route.properties.LEGENDA_1)
			this.map.setView(this.routesInfo[index - 1].start, 15);
    }
  }
}
</script>

<style lang="scss" scoped>

ul {
  @screen md {
    column-count: 2;
    column-gap: 20px;
  }
}

</style>