import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import SvgInline from '@/components/SvgInline'
import SvgIcon from '@/components/SvgIcon'

const app = createApp(App)
app.component('SvgInline', SvgInline)
app.component('SvgIcon', SvgIcon)

app.use(store)
app.use(router)
app.mount('#app')
