<template>
	<div class="leaflet-fietsenstallingen hidden" />
</template>

<script>

import L from 'leaflet';
// import { MarkerClusterGroup } from "leaflet.markercluster";
// import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

export default {
	props: {
		map: {
			type: [Object],
			custom: false
		},
		data: {
			type: [Object],
			custom: false
		},
	},
  data: function () {
    return {
      mymap: null,
      buurtstalling: null,
      fietsenstalling: null
    }
  },
	mounted() {
    const icon = L.Icon.extend({
      options: {
        shadowUrl: require('@/assets/images/pin-shadow.png'),
        iconSize: [36, 39],
        iconAnchor: [18, 39],
        popupAnchor: [0, -28],
        shadowSize: [42, 45],
        shadowAnchor: [21, 42]
      }
    })
    this.buurtstalling = new icon({iconUrl: require('@/assets/images/pin-buurtstalling-2x.png')})
    this.fietsenstalling = new icon({iconUrl: require('@/assets/images/pin-fietsenstalling-2x.png')})
    
    /*
		const markerCluster = new MarkerClusterGroup({ 
      spiderfyOnMaxZoom: false,
      showCoverageOnHover: false,
      iconCreateFunction: function(cluster) {
        var clusterSize = "small";
        if (cluster.getChildCount() >= 10) {
          clusterSize = "medium";
        }
        return new L.DivIcon({
          html: '<div><span>' + cluster.getChildCount() + '</span></div>',
          className: 'marker-cluster marker-cluster-' + clusterSize,
          iconSize: new L.Point(50, 50)
        });
      }
    });
    */
    const layergroup = L.layerGroup()
		var geoCluster = this.addGeoJsonLayerWithClustering([this.data])
		layergroup.addLayer(geoCluster)
    this.$emit('setLayer', layergroup) 
	},	
	methods: {
		addGeoJsonLayerWithClustering(data) {
			var geoJsonLayer = L.geoJson(data, {
        pointToLayer: (feature, latlng) => {
          const icon = (feature.properties.name === "Buurtstalling Den Haag") ? this.buurtstalling : this.fietsenstalling
          return L.marker(latlng, {
            icon: icon,
            riseOnHover: true
          })
        },
				onEachFeature: this.onEachFeature
			});
			return geoJsonLayer;
		},	
		onEachFeature(feature, layer) {
      layer.bindPopup('<div class="text-sm font-bold">Fietsenstalling</div><div class="text-base">'+feature.properties.name+"</div>")		
		}
	}
}
</script>

<style>
.leaflet-popup-content-wrapper {
	background: black;
	color: white;
	border-radius: 4px;
}
.leaflet-container a.leaflet-popup-close-button {
	color: white;
}
.leaflet-popup-tip { 
	background: black;
  margin-top: -16px;
	border-radius: 2px;
}
</style>