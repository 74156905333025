<template>
	<div class="leaflet-markers hidden" />
</template>

<script>

import L from 'leaflet';

export default {
	props: {
		map: {
			type: [Object],
			custom: false
		},
		data: {
			type: [Object],
			custom: false
		},
	},
  data: function () {
    return {
      markers: [],
      icons: []
    }
  },
	mounted() {
    const icon = L.Icon.extend({
      options: {
        shadowUrl: require('@/assets/images/pin-shadow.png'),
        iconSize: [36, 39],
        iconAnchor: [18, 39],
        popupAnchor: [0, -28],
        shadowSize: [42, 45],
        shadowAnchor: [21, 42]
      }
    })
    this.icons['buurtstalling'] = [ new icon({iconUrl: require('@/assets/images/pin-buurtstalling-2x.png')}), 'Buurtstalling' ]
    this.icons['fietsenstalling'] = [ new icon({iconUrl: require('@/assets/images/pin-fietsenstalling-2x.png')}), 'Fietsenstalling' ]
    this.icons['restaurant'] = [ new icon({iconUrl: require('@/assets/images/pins/restaurant.png')}), 'Restaurant' ]
    this.icons['reparatie'] = [ new icon({iconUrl: require('@/assets/images/pins/reparatie.png')}), 'Reparatie' ]
    this.icons['natuurgebied'] = [ new icon({iconUrl: require('@/assets/images/pins/natuurgebied.png')}), 'Natuurgebied' ]
    this.icons['fotomoment'] = [ new icon({iconUrl: require('@/assets/images/pins/fotomoment.png')}), 'Fotomoment' ]
    this.icons['info'] = [ new icon({iconUrl: require('@/assets/images/pins/info.png')}), 'Info' ]
    this.icons['attractie'] = [ new icon({iconUrl: require('@/assets/images/pins/attractie.png')}), 'Attractie' ]
    this.icons['dingenomtedoen'] = [ new icon({iconUrl: require('@/assets/images/pins/dingenomtedoen.png')}), 'Dingen om te doen' ]
    this.icons['bezienswaardigheid'] = [ new icon({iconUrl: require('@/assets/images/pins/bezienswaardigheid.png')}), 'Bezienswaardigheid' ]
    this.icons['lunchroom'] = [ new icon({iconUrl: require('@/assets/images/pins/lunchroom.png')}), 'Lunchroom' ]
    this.icons['restaurant'] = [ new icon({iconUrl: require('@/assets/images/pins/restaurant.png')}), 'Restaurant' ]
    this.icons['wegwerkzaamheden'] = [ new icon({iconUrl: require('@/assets/images/pins/wegwerkzaamheden.png')}), 'Wegwerkzaamheden' ]

		const layer = this.addGeoJsonLayerWithClustering(this.data)
    this.$emit('setLayer', layer)
    this.$store.commit('setMarkers', this.markers)
	},	
	methods: {
		addGeoJsonLayerWithClustering(data) {
      return L.geoJson(data, {
        pointToLayer: (feature, latlng) => {
          const icon = this.icons[feature.properties.category][0]
          let marker = L.marker(latlng, {
            icon: icon
          })
          this.markers[feature.properties.id] = { "marker": feature, "leaflet": marker };
          return marker
        },
				onEachFeature: this.onEachFeature
			})
		},	
		onEachFeature(feature, layer) {
      let popup = '<div class="text-xs lg:text-sm font-bold">'+this.icons[feature.properties.category][1]+'</div><div class="text-sm lg:text-base">'+feature.properties.popup+'</div>'
      if (feature.properties.link !== undefined && feature.properties.link !== null) {
        popup += '<div class="mt-2"><a class="text-sm lg:text-base underline hover:no-underline text-white" target="_blank" href="'+feature.properties.link+'">';
        if (feature.properties.link_title) {
          popup += feature.properties.link_title
        } else {
          popup += 'bekijk'
        }
        popup += '</a></div>';
      }
      if (feature.properties.overlay) {
        popup += '<div class="mt-4"><button class="read-more text-sm lg:text-base bg-white hover:bg-gray-100 text-black rounded px-4 py-2 text-sm font-bold">lees verder</button></div>'
      }
			layer.bindPopup(popup).on("popupopen", () => {
        if (feature.properties.overlay) {
          document.querySelector('.read-more').addEventListener('click', (event) => {
            event.preventDefault()
            this.map.closePopup()
            this.$store.commit('setOverlay', feature.properties.overlay)
            this.$store.commit('openOverlay', true)            
          })
        }
      })
		}
	}
}
</script>

<style >

</style>