<template>
<div class="bg-white rounded shadow lg:shadow-none sterfiets-info">
  <div class="mt-4 px-4 fixed w-full h-8 top-0 left-0 z-20 lg:hidden">
    <a @click="closeRouteInfo" class="block flex w-full h-full bg-blue-500 rounded-t">
      <svg-inline class="w-4 m-auto" name="svg-close" />
    </a>
  </div>  
  <div class="info w-full relative p-4 pt-12 lg:pt-8 border-b">
    <h2 class="text-3xl font-normal">Route informatie</h2>    
    <div class="w-full flex flex-wrap mt-4">
      <div class="w-2/5">
        <h3 class="text-xl mb-1">Fietsafstand</h3>
        <div class="flex flex-wrap items-end">
          <h4 class="text-5xl font-bold">{{ route.distance }}</h4>
          <span class="text-base font-bold ml-2 mb-1">km</span>
        </div>
      </div>
      <div class="w-3/5">
        <div class="from relative pl-8">
          <h6 class="font-bold text-base">van</h6>
          <h5 class="font-light">{{ route.from }}</h5>
          <span class="line" :style="{ background: route.color }"></span>
        </div>
        <div class="to relative pl-8">
          <h6 class="font-bold text-base mt-2">naar</h6>
          <h5 class="font-light">{{ route.to }}</h5>
        </div>
      </div>
    </div>
  </div>
  <div class="p-4 border-b">
    <h3 class="text-xl mb-1">Hoeveel minuten is het fietsen?</h3>
    <div class="flex flex-wrap items-end">
      <h4 class="text-5xl font-bold">{{ route.duration }}</h4>
      <span class="text-base font-bold ml-2 mb-1">minuten</span>
    </div>
  </div>
  <div class="p-4 border-b">
    <h3 class="text-xl mb-1">Deze route gaat door de volgende wijken</h3>
    <ul>
      <li v-for="neighborhood in route.neighborhoods" :key="neighborhood" class="font-bold px-4 py-2 border inline-block mt-2 mr-2 rounded-full">{{ neighborhood }}</li>
    </ul>
  </div>
  <div class="p-4">
    <h3 class="text-xl mb-1">Meer over deze Route</h3>
    <p class="text-base font-light">
      {{ route.info }}
    </p>
  </div>
</div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'RouteInfo',
	props: {
		data: {
			type: [Object],
			custom: false
		}
	},
  computed: {
    ...mapState(['active']),
    route: function () {
      return this.data.sterfietsroutes[this.active]
    }
  },
  mounted () {    
  },
  methods: {
    closeRouteInfo() {
      this.$emit('closeRouteInfo')
    }
  }
}
</script>

<style lang="scss" scoped>
  .sterfiets-info:after {    
    content: '';
    z-index: -1;
    @apply fixed top-0 left-0 bg-black h-full w-full opacity-50;
    @screen lg { @apply hidden; }
  } 
  .to:after {
    content: '';
    @apply absolute h-4 w-4 rounded-full border-4 border-black bg-white top-1 left-0 z-20;
  }
  .to:before {
    content: '';
    @apply absolute h-8 w-4 bg-white top-5 left-0 z-20;
  }  
  .line {
    @apply absolute h-24 w-1 bg-red-500 top-1 left-1.5 z-10;
  }
  .from:after {
    content: '';
    @apply absolute h-4 w-4 rounded-full border-4 border-black bg-white top-1 left-0 z-20;
  }  
</style>