<template>
  <span class="icon" :class="`icon-${name}`" v-html="url" />
</template>

<script>

export default {
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  computed: {
    url () {
      return require(`@/assets/images/${this.name}.svg?raw`)
    }
  }
}

</script>

<style lang="scss">

  .icon {
    display: inline-block;

    svg {
      width: 1em;
      height: 1em;
      display: block;
      font-size: inherit;
      fill: currentColor;
    }

  }

</style>
