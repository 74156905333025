import { createStore } from 'vuex'

export default createStore({
  state: {
    name: null,
    active: null,
    routes: {},
    markers: {},
    stops: null,
    overlay: null,
    routesStopsPoi: {},
    openMenu: false,
    openOverlay: false,
    currentName: 'Stalling Den Haag Centraal'
  },
  mutations: {
    setCurrentName (state, data) {
      state.currentSong = data
    },  
    setActive (state, data) {
      state.active = data
    },
    setName (state, data) {
      state.name = data
    },
    setMarkers (state, data) {
      state.markers = data
    },
    setRoutesStopsPoi (state, data) {
      state.routesStopsPoi = data
    },        
    setRoutes (state, data) {
      state.routes = data
    },
    setStops (state, data) {
      state.stops = data
    },
    setOverlay (state, data) {
      state.overlay = data
    },
    openMenu (state, data) {
      state.openMenu = data
    },
    openOverlay (state, data) {
      state.openOverlay = data
    }
  },
  getters: {
    currentName: state => {
      return state.currentName;
    }
  },
  actions: {
  },
  modules: {
  }
})
