<template>
	<div>
    <div class="bg-yellow w-full rounded-t flex flex-wrap items-center h-2 lg:h-auto p-0 lg:p-4">
      <h1 class="hidden lg:block text-2xl color-black">
        Sterfietsroutes
      </h1>
    </div>
    <div class="p-4">
      <button @click="showRoutes" title="selecteer een sterfietsroute" class="button-select relative border-2 border-black font-bold w-full rounded-full pl-4 lg:pl-6 pr-10 py-2 lg:py-3 text-left text-base lg:text-lg leading-snug">
        <span v-if="active === null">selecteer een sterfietsroute</span>
        <span v-else>{{ name }}</span>

      </button>
      <a @click="showAllRoutes" href="#" title="toon alle sterfietsroutes op de kaart" class="block lg:text-base underline hover:no-underline mt-2">Toon alle sterfietsroutes op de kaart</a>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'Menu',
	props: {
		map: {
			type: [Object],
			custom: false
		}
	},
  computed: {
    ...mapState(['openMenu', 'active', 'name'])
  },
  mounted() {

  },
  methods: {
    showRoutes (event) {      
      event.preventDefault()
      this.$store.commit('openMenu', true)
    },
    showAllRoutes (event) {
      event.preventDefault()
      this.$store.commit('setActive', null)
      this.$emit('showAllRoutes')
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-select {
    &:after {
      content: '';
      background-image: url('~@/assets/images/select.png');
      background-repeat: no-repeat;
      background-size: cover;
      @apply w-3 h-3 absolute right-3 top-1/2 transform -translate-y-1/2;
      @screen lg {
        @apply w-4 h-4 right-4;
      }
    }
  }
</style>