<template>
	<div class="leaflet-stops hidden" />
</template>

<script>

import { mapState } from 'vuex'
import L from 'leaflet';

export default {
	props: {
		map: {
			type: [Object],
			custom: false
		},
		data: {
			type: [Object],
			custom: false,
      isMobile: false
		},
		sterfietsroutes: {
			type: [Object],
			custom: false
		},
	},
  computed: {
    ...mapState(['stops'])
  },
	mounted() {
    this.isMobile = ('ontouchstart' in document.documentElement && navigator.userAgent.match(/Mobi/))

    let group = L.layerGroup()
    this.data.forEach((item, index) => {      
      let stops = this.addGeoJsonLayer(item.stops, this.sterfietsroutes[index].color, index)
      group.addLayer(stops)
    });
    
    this.$store.commit('setStops', group)
    this.stops.addTo(this.map)
	},	
	methods: {
		addGeoJsonLayer(data, color, index) {
      return L.geoJson(data, {
        pointToLayer: (feature, latlng) => {
          feature.route = index;
          return L.circleMarker(latlng, {
            radius: 9,
            fillColor: color,
            color: "#ffffff",
            weight: 4,
            opacity: 1,
            fillOpacity: 1
          })
        },
				onEachFeature: this.onEachFeature
			})
		},	
		onEachFeature(feature, layer) {
      let popup = L.popup({
        'className' : (this.isMobile) ? 'popupWhite popupWhiteMobile' : 'popupWhite'
      }).setContent('<span class="text-sm block font-bold">Sterfietsroute '+(feature.route + 1)+'</span><span class="text-base">'+feature.properties.popup+'</span>');
      layer.bindPopup(popup)
      if(this.isMobile) {
        layer.on('click', function () {
          this.openPopup();
        })
      } else {
        layer.on('mouseover', function () {
          this.openPopup();
        })
        layer.on('mouseout', function () {
          this.closePopup();       
        })
      }
		}
	}
}
</script>

<style>
.popupWhite .leaflet-popup-tip,
.popupWhite .leaflet-popup-content-wrapper {
  background: white;
  color: black;
}

.leaflet-container .popupWhiteMobile a.leaflet-popup-close-button {
	color: black;
}
</style>