<template>
  <div v-if="showPopup" class="hidden md:flex items-center px-4 py-4 bg-white fixed right-4 lg:right-8 bottom-24 lg:bottom-8 h-14 font-bold text-base rounded-bl rounded-tl lg:rounded-bl-0 lg:rounded-br z-50">
    <svg-icon name="accessibility" class="leading-none mr-2 text-4xl" />
    <span>
      naar de
    </span>
    <a href="https://www.denhaag.nl/nl/in-de-stad/verkeer-en-vervoer/fietsen/netwerk-sterfietsroutes.htm" class="inline-block ml-1 underline hover:no-underline" target="_blank">
      toegankelijke versie
    </a>
    <button class="leading-none ml-4" @click="hidePopup">
      <svg-icon name="close" class="text-xs" />
    </button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showPopup: true
    }
  },
  methods: {
    hidePopup () {
      this.showPopup = false
    }
  }
}
</script>

<style scoped>

.read-more {
  width: 42px;
  height: 42px;
  @screen sm {
    width: 48px;
    height: 48px;
  }
}

</style>
