<template>
  <span class="svg-inline" :class="`svg-inline-${name}`" v-html="url" />
</template>

<script>

export default {
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  computed: {
    url () {
      return require(`@/assets/images/${this.name}.svg?raw`)
    }
  }
}

</script>

<style lang="scss">

  .svg-inline {

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }

  }

</style>
