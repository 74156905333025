import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Stops from '../views/Stops.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Den Haag Fietst! - Sterfietsroutes'
    }
  },
  {
    path: '/stops',
    name: 'Stops',
    component: Stops,
    meta: {
      title: 'Stops'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
	document.title = `${to.meta.title}`
	next()
})

export default router
