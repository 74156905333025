<template>
	<div class="leaflet-marker-cluster-group hidden">
    <fietsenstallingen v-if="markersStallingenData" :map="map" :data="markersStallingenData" @setLayer="setLayer" />
    <markers v-if="markersData" :map="map" :data="markersData" @setLayer="setLayer" />    
  </div>
</template>

<script>

import axios from 'axios'
import { mapState } from 'vuex'
import L from "leaflet"
import { MarkerClusterGroup } from "leaflet.markercluster"
import Fietsenstallingen from "@/components/Fietsenstallingen.vue"
import Markers from "@/components/Markers.vue"
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

export default {
  name: 'MarkerClusterGroup',
  components: {
		Fietsenstallingen,
    Markers
  },  
	props: {
		map: {
			type: [Object],
			custom: false
		}
	},
  data: function () {
    return {
      markersData: null,
      markersStallingenData: null,
      markerCluster: null,
      layergroup: L.layerGroup(),
      count: 0
    }
  },
  computed: {
    ...mapState(['openMenu', 'active', 'name'])
  },
  created () {
    this.getData('markersStallingenData', 'fietsenstallingen.json')
    fetch(process.env.VUE_APP_API_URL+'/api/collections/get/Markers?token=1fa5a8424c90961852db5444c6994d')
      .then(res=>res.json())
      .then((res) => {
        let items = []
        res.entries.forEach((marker) => {
          // console.log(marker)
          let overlay = (marker.overlay[0] && marker.overlay[0].value.title && marker.overlay[0].value.title !== '') ? marker.overlay[0].value : null
          const item = { 
            "type": "Feature",
            "properties": { "id": marker._id, "name": marker.name, "category": marker.category, "popup": marker.popup, "link": marker.link, "link_title": marker.link_title, "overlay": overlay },
            "geometry": { "type": "Point", "coordinates": [marker.coordinates.lng, marker.coordinates.lat] }
          }
          items.push(item)
        });
        this.markersData = items
      });
  },
  mounted() {

		this.markerCluster = new MarkerClusterGroup({ 
      spiderfyOnMaxZoom: false,
      showCoverageOnHover: false,
      iconCreateFunction: function(cluster) {
        var clusterSize = "small";
        if (cluster.getChildCount() >= 10) {
          clusterSize = "medium";
        }
        return new L.DivIcon({
          html: '<div><span>' + cluster.getChildCount() + '</span></div>',
          className: 'marker-cluster marker-cluster-' + clusterSize,
          iconSize: new L.Point(50, 50)
        });
      }
    })
  },
  methods: {
    async getData(name, file) {
      const { data } = await axios.get(`${process.env.VUE_APP_BASE_URL}/static/json/${file}`)
      this[name] = data
    },
    setLayer(layer) {
      // this.layergroup.addLayer(layer)
      this.markerCluster.addLayer(layer)
      this.$emit('setCluster', this.markerCluster)
    }
  }
}
</script>

<style lang="scss">
.leaflet-popup-content-wrapper {
	background: black;
	color: white;
	border-radius: 4px;
}
.leaflet-popup-close-button {
	color: white;
}
.leaflet-popup-tip { 
	background: black;
  margin-top: -16px;
	border-radius: 2px;
}
.marker-cluster {
  border-radius: 100%;
  @apply filter drop-shadow-sm;
}
.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
  background-color: rgba(255, 255, 255, 1);
}
.marker-cluster-small div,
.marker-cluster-medium div,
.marker-cluster-large div {
  @apply text-base text-black bg-white font-bold;
  margin-left: 10px;
  margin-top: 9px;
}
</style>