<template>
  <router-view/>
</template>

<style lang="scss">
body {
  overflow: hidden;
  background-image: url('~@/assets/images/urban_pattern.png');
  background-size: 127px 170px;
  background-position: -70px;
  background-repeat: repeat-y;
}

#app {
  color: black;
}

html, body, #app {
  height: 100%;
}
</style>
