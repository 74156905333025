<template>
  <div v-if="overlay && openOverlay" class="w-full 2xl:w-3/5 h-full bg-black fixed lg:absolute top-0 right-0 z-20 lg:rounded-t 2xl:rounded-tr">
    <button @click="closeOverlay" class="w-8 h-8 absolute top-2 left-2 bg-gray-800 lg:bg-transparent rounded-full">
      <span class="w-4 lg:w-5 block m-auto">
        <svg-inline name="svg-close" />
      </span>
    </button>
    <div class="w-full h-full p-6 lg:p-12 pt-16 overflow-y-auto">
      <article>
        <h2 v-if="overlay.title" class="text-3xl lg:text-4xl text-white">{{ overlay.title }}</h2>
        <div v-if="overlay.image" class="figure mb-4">
          <div class="relative pb-ratio">
            <img class="absolute h-full w-full object-cover" :src="articleImage" :title="articleTitle">          
          </div>
          <h6 v-if="overlay.image.meta" class="text-white text-right mt-2">{{ overlay.image.meta.citation }}</h6>
        </div>
        <div class="text-white mt-4">
          <span v-html="overlay.text"></span>
        </div>
      </article>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['overlay', 'openOverlay']),
    articleImage() {
      return `${process.env.VUE_APP_API_URL}/${this.overlay.image.path}`
    },
    articleTitle() {
      return (this.overlay.image.meta) ? this.overlay.image.meta.title : this.overlay.title
    }    
  },
  methods: {
    closeOverlay(e) {
      e.preventDefault()
      this.$store.commit("openOverlay", false);
    }
  }
}
</script>

<style lang="scss" >
  article h1, article h2, article h3, article h4, article h5, article h6, article p {
    @apply mb-4;
  }
  article h1 {
    @apply text-3xl;
    @screen lg { @apply text-4xl; }
  }
  article h2 {
    @apply text-2xl;
    @screen lg { @apply text-3xl; }
  }
  article h3 {
    @apply text-xl;
    @screen lg { @apply text-2xl; }
  }
  article h4 {
    @apply font-bold text-lg;
    @screen lg { @apply text-xl; }
  }
  article h5 {
    @apply font-bold text-base;
    @screen lg { @apply text-lg; }
  }
  article h6 {
    @apply text-sm;
  }
  article p {
    @apply text-base;
  }  
</style>